class RyanJames {

  constructor(bgImg = '/assets/images/ryan-james.jpg', background = '.rj__background', about = '.rj__about') {
    this.bgImg = bgImg
    this.$background = document.querySelectorAll(background)[0];
    this.$about = document.querySelectorAll(about)[0];
  }

  loadBackground() {
    const _img = new Image();
    _img.onload = () => {
      this.$background.className = this.$background.className + ' reveal';
      this.$about.className = this.$about.className + ' reveal';
    }
    _img.src = this.bgImg;
  }

  initialize() {
    this.loadBackground();
  }
}
document.addEventListener('DOMContentLoaded', () => {
  new RyanJames().initialize();
});
